import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '../components/global.css'
import { SignageFoodStyle } from '../components/signageFoodStyle.js'

class Signage extends React.Component {
  render() {
    let medias = get(this, 'props.data.contentfulDigitalSignage.media')

    return <SignageFoodStyle medias={medias} />
  }
}

export default Signage

export const pageQuery = graphql`
  query LP_SignageFoodQuery {
    contentfulDigitalSignage(contentful_id: { eq: "1smdCOyQzJQ8Aho0WiRw81" }) {
      media {
        title
        gatsbyImageData(placeholder: BLURRED, quality: 100, cropFocus: TOP)
        fluid {
          src
        }
      }
    }
  }
`
